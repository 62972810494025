<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{first} {last}</h4>
							<p>Account Owner</p>
						</div>
					</a-col>
					
				</a-row>
			</template>
		</a-card>
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24">

			<!-- Profile Information Column -->
			<a-col :span="24" :md="24" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation :userData="userData"></CardProfileInformation>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->

		</a-row>

	</div>
</template>

<script>

	import CardPlatformSettings from "../components/Cards/CardPlatformSettings"
	import CardProfileInformation from "../components/Cards/CardProfileInformation"
	import CardConversations from "../components/Cards/CardConversations"
	import CardProject from "../components/Cards/CardProject"
	import UserService from '../services/UserService'

	export default ({
		computed: {
			currentUser() {
				return this.$store.state.auth.user
			}
		},
		components: {
			CardPlatformSettings,
			CardProfileInformation,
			CardConversations,
			CardProject,
		},
		setup () {
			return { UserService }
		},	
		mounted () {
			this.refreshData()
		},
		methods: {
			async refreshData() {
				this.userData = await UserService.getUserByEmail(this.currentUser.email)
				console.log()
			}
		},
		data() {
			return {
				// Active button for the "User Profile" card's radio button group.
				profileHeaderBtns: 'overview',

				userData: {}
			}
		},
	})

</script>

<style lang="scss">
</style>