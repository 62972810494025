<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Account Information</h6>
		</template>
		
		<a-descriptions title="" :column="1">
			<a-descriptions-item label="Company Name">
				{{ this.userData.companyName}}
			</a-descriptions-item>
			<!--<a-descriptions-item label="Mobile">
				727-244-6988
			</a-descriptions-item>-->
			<a-descriptions-item label="Email">
				{{ this.userData.email}}
			</a-descriptions-item>
			<!--<a-descriptions-item label="Location">
				USA
			</a-descriptions-item>-->
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>

	export default ({
		props: {
			userData: {}
		},
		data() {
			return {
			}
		},
	})

</script>